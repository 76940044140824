import * as React from "react"
import Header from "../components/team/Header"
import Layout from "../components/general/Layout";
import IneP from "../components/team/IneP"
import KristofC from "../components/team/KristofC"
import AnnetteVT from "../components/team/AnnetteVT"
import VeerleR from "../components/team/VeerleR"
import TessS from "../components/team/TessS"
import LauraVI from "../components/team/LauraVI"
import Yana from "../components/team/Yana"
import Tom from "../components/team/Tom"

const TeamPage = () => {
    return (
      <Layout>
        <Header></Header>
        <KristofC></KristofC>
        <AnnetteVT></AnnetteVT>
        <VeerleR></VeerleR>
        <IneP></IneP>
        <TessS></TessS>
        <LauraVI></LauraVI>
        <Tom></Tom>
        <Yana></Yana>
      </Layout>
    );
}

export default TeamPage
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';

const Tom = () => {
    return (
      <div className="bg-gray-white my-24 2xl:my-48 font-inter" id="Tom">
        <div className="grid grid-cols-12">
          {/* mobile foto */}
          <div className="col-span-0 md:col-span-1 lg:hidden"></div>
          <div className="col-span-12 md:col-span-10 lg:hidden max-h-screen">
            <StaticImage
              src="https://res.cloudinary.com/yungnuts/image/upload/v1643545599/CVT/tomMobile_vriueb.jpg"
              alt="Mooie foto"
              className="h-full"
            />
          </div>
          <div className="col-span-0 md:col-span-1 lg:hidden"></div>
          {/* teskt */}
          <div className="col-span-0 md:col-span-1 2xl:col-span-2 "></div>

          <div className="col-span-12 px-12 md:px-0 md:col-span-10 lg:col-span-4 2xl:col-span-3 mt-16 lg:mt-0">
            {/* INfo */}
            <div className="flex flex-col justify-center ">
              {/* Naam */}
              <div className="text-3xl sm:text-5xl lg:text-5xl font-semibold text-blue">
                Tom Hofman
              </div>
              {/* Titel */}
              <div className="mt-2 text-lg italic text-blue-gray">
                Administratieve duizendpoot
              </div>

              {/* start  */}
              <div className="mt-14 text-lg">
                Balie CVT advocaten sinds 1 augustus 2021
              </div>

              {/* grote tekst info */}
              <div className="mt-20 text-lg">
                Tom zorgt voor het onthaal op ons kantoor. Hij
                ondersteunt de advocaten van CVT op administratief vlak.
              </div>
              {/* email */}
              <div className="flex flex-row items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ionicon"
                  viewBox="0 0 512 512"
                  className=" fill-current text-blue-effect"
                  width="24"
                  height="24"
                >
                  <title>Mail</title>
                  <rect
                    x="48"
                    y="96"
                    width="416"
                    height="320"
                    rx="40"
                    ry="40"
                    fill="none"
                    stroke="#3a86ff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                  />
                  <path
                    fill="none"
                    stroke="#3a86ff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    d="M112 160l144 112 144-112"
                  />
                </svg>
                <div className=" text-lg my-10">th@cvt-advocaten.be</div>
              </div>
            </div>
          </div>
          <div className="col-span-1 2xl:col-span-1 "></div>
          {/* normale foto */}
          <div className="hidden lg:block col-span-0 lg:col-span-1 2xl:col-span-1  mt-16 lg:mt-0"></div>
          <div className="hidden lg:block col-span-12 lg:col-span-4 2xl:col-span-3 max-h-screen mt-16 lg:mt-0">
            <StaticImage
              src="https://res.cloudinary.com/yungnuts/image/upload/v1643545589/CVT/tom_uykl81.jpg"
              alt="Mooie foto"
              className="h-full"
            />
          </div>
          <div className="hidden lg:block col-span-0 lg:col-span-1 2xl:col-span-2  mt-16 lg:mt-0"></div>
        </div>
      </div>
    );
}

export default Tom

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const TessS = () => {
    return (
      <div className="bg-gray-white my-24 2xl:my-48 font-inter" id="Tess">
        <div className="grid grid-cols-12">
          {/* mobile foto */}
          <div className="col-span-0 md:col-span-1 lg:hidden"></div>
          <div className="col-span-12 md:col-span-10 lg:hidden max-h-screen">
            <StaticImage
              src="https://res.cloudinary.com/yungnuts/image/upload/v1644156737/CVT/930E786F-9CF2-4025-A567-2ECC3160F2BC_1_201_a_xis66x.jpg"
              alt="Mooie foto"
              className="h-full"
            />
          </div>
          <div className="col-span-0 md:col-span-1 lg:hidden"></div>
          {/* teskt */}
          <div className="col-span-0 md:col-span-1 2xl:col-span-2 "></div>

          <div className="col-span-12 px-12 md:px-0 md:col-span-10 lg:col-span-4 2xl:col-span-3 mt-16 lg:mt-0">
            {/* INfo */}
            <div className="flex flex-col justify-center ">
              {/* Naam */}
              <div className="text-3xl sm:text-5xl lg:text-5xl font-semibold text-blue">
                Tess Speltens
              </div>
              {/* Titel */}
              <div className="mt-2 text-lg italic text-blue-gray">Advocaat</div>

              {/* start  */}
              <div className="mt-14 text-lg">
                Balie Dendermonde sinds 2 april 2020
              </div>

              {/* grote tekst info */}
              <div className="mt-20 text-lg">
                Master in de rechten aan de Universiteit Gent
                <br /> Master na master in het notariaat aan de Universiteit
                Gent
              </div>
              {/* email */}
              <div className="flex flex-row items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ionicon"
                  viewBox="0 0 512 512"
                  className=" fill-current text-blue-effect"
                  width="24"
                  height="24"
                >
                  <title>Mail</title>
                  <rect
                    x="48"
                    y="96"
                    width="416"
                    height="320"
                    rx="40"
                    ry="40"
                    fill="none"
                    stroke="#3a86ff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                  />
                  <path
                    fill="none"
                    stroke="#3a86ff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    d="M112 160l144 112 144-112"
                  />
                </svg>
                <div className=" text-lg my-10">ts@cvt-advocaten.be</div>
              </div>
            </div>
          </div>
          <div className="col-span-1 2xl:col-span-1 "></div>
          {/* normale foto */}
          <div className="hidden lg:block col-span-0 lg:col-span-1 2xl:col-span-1  mt-16 lg:mt-0"></div>
          <div className="hidden lg:block col-span-12 lg:col-span-4 2xl:col-span-3 max-h-screen mt-16 lg:mt-0">
            <StaticImage
              src="https://res.cloudinary.com/yungnuts/image/upload/v1644156720/CVT/_YDG0449_v0kjrk.jpg"
              alt="Mooie foto"
              className="h-full"
            />
          </div>
          <div className="hidden lg:block col-span-0 lg:col-span-1 2xl:col-span-2  mt-16 lg:mt-0"></div>
        </div>
      </div>
    );
}

export default TessS
